import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route, Switch
} from "react-router-dom";
import './App.css';
import logo from './logo.svg';
import DownloadFiles from './Routes/DownloadFiles';
import DownloadFilesBrotli from './Routes/DownloadFilesBrotli';
import DownloadFilesGzip from './Routes/DownloadFilesGzip';

const EnvironmentContext = React.createContext({});


const getBuildMessage = () => (
  <EnvironmentContext.Consumer>
    {
      (context) => (
        <div className={`build-message ${'ADSK_ENV' in context ? context.ADSK_ENV.toLowerCase() : 'dev'}`}>
          <p> This environment/region has the following properties:</p>
          <ul>
            {Object.keys(context).map(k => <li key={k}>{k}: {(context[k] instanceof String ? context[k] : JSON.stringify(context[k]))}</li>)}
          </ul>
        </div>
      )
    }
  </EnvironmentContext.Consumer>
);


function App({adskEnv}) {
  return (
    <EnvironmentContext.Provider value={adskEnv}>
      <Router>
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            {getBuildMessage()}
            <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
              Learn React
            </a>
            <nav>
            <ul>
              <li>
                <Link to="/">Test No Compression</Link>
              </li>
              <li>
                <Link to="/gzip">Test Gzip Compression</Link>
              </li>
              <li>
                <Link to="/brotli">Test Brotli Compression</Link>
              </li>
            </ul>
          </nav>
          </header>
          <article className="App-body">
            <Switch>
              <Route path="/gzip">
                <DownloadFilesGzip/>
              </Route>
              <Route path="/brotli">
                <DownloadFilesBrotli/>
              </Route>
              <Route path="/">
                <DownloadFiles/>
              </Route>
            </Switch>
          </article>
        </div>
      </Router>
    </EnvironmentContext.Provider>
  );
}

export default App;
