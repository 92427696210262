import React, { useState } from 'react';

const WasmDownloadTest = ({downloadAs = ''}) => {
    const [wasmState, setWasmDownloadState] = useState({wasmDownloadState: 'pending', data: {}});

    if (wasmState.wasmDownloadState === 'pending') {
        const t_start = window.performance.now()
        fetch('/rustpython.wasm' + downloadAs)
            .then(res => {
                res.arrayBuffer().then(() => {
                    const data = {
                        time: window.performance.now() - t_start,
                        contentType: res.headers.get('content-type'),
                        contentEncoding: res.headers.get('content-encoding'),
                    };

                    setWasmDownloadState({wasmDownloadState: 'done', data});
                });
            })
            .catch(() => setWasmDownloadState({wasmDownloadState: 'fetching', data: {}}))
    }

    return (Object.keys(wasmState.data).length > 0) ? (
        <>
            <p>Download State: {wasmState.wasmDownloadState}</p>
            <code>
                <p>Download Time: {wasmState.data.time / 1000} Seconds</p>
                <p>Content Type: {wasmState.data.contentType}</p>
                <p>Content Encoding: {wasmState.data.contentEncoding}</p>
            </code>
        </>
    ) : (
        <p>Pending Download</p>
    )
};

const BaseDownload = ({downloadAs = ''}) => {
    return (
        <>
            <p>WASM FILE DOWNLOAD TEST:</p>
            <br/>
            <WasmDownloadTest downloadAs={downloadAs}/>
            <br/>
            <h2>VIdeo Test</h2>
            <video controls autoPlay loop muted>
                <source src={"/SampleVideo_1280x720_30mb.mp4" + downloadAs}></source>
            </video>
            <br/>
            <h2>JPG Test</h2>
            <img className="Resize" src={"/SampleJPGImage_15mbmb.jpg" + downloadAs} alt="logo test"></img>
            <h2>PNG Large Test</h2>
            <img className="Resize" src={"/SamplePNGImage_30mbmb.png" + downloadAs} alt="logo test"></img>
        </>
    )
}

export default BaseDownload